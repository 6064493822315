<template>
  <div class="cloud-project-item">
    <div class="cloud-project-item-box">
      <h3>{{item.name}}</h3>
      <p>{{item.instructions}}</p>
      <span>{{item.price}}</span>
      <a :href="onLine" target="_blank">获取折扣</a>
    </div>
  </div>
</template>
<script lang="ts">
import { onLine } from "@/data";
export default {
  props:{
    item:{
      type:Object,
      default:()=>{}
    }
  },
  data(){
    return {
      onLine
    }
  }
}
</script>
<style lang="less">
.cloud-project-item{
  padding: 2px;
  width: 50%;
  background: #FFE7C5;
  & &-box{
    padding: 15px 10px 10px;
    font-family: Source Han Sans CN;
    border: 1px solid #773949;
    color: #5C0001;
    >h3{
      font-size: 15px;
      font-weight: bold;
      line-height: 1.5;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    >p{
      font-size: 13px;
      font-weight: 400;
      margin-top: 10px;
      height: 55px;
      line-height: 1.5;
      -webkit-line-clamp: 3;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      text-align: justify;
    }
    >span{
      display: block;
      color: #E80B10;
      font-size: 15px;
      font-weight: bold;
      margin: 10px auto;
      text-decoration:line-through;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    >a{
      text-align: center;
      display: block;
      appearance: none;
      border: 0 none;
      background-color: #E80B10;
      border-radius: 1px;
      line-height: 30px;
      width: 100%;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>
